import './AppFooter.css';
import * as React from 'react';


function AppFooter() {
    // let year = new Date().getFullYear().toString();
    return (
        <footer className="footer mt-auto py-3">
            <div className="row-cols-auto">
                <div className="col-10 footer-brand">
                {/*<div className="col-10 footer-brand animated carousel-fade">*/}
                    <a className="brand " href="https://sevasoft.cz">
                        Vytvořil <img src="/assets/img/logo-60x34.gif" alt="SEVASOFT Logo" title="Software Atelier" height="10"/>
                        &nbsp;SEVASOFT Atelier</a> s <img src="/assets/img/srdicko.webp" alt="Srdíčko" title="s láskou" height="10"/>
                </div>
                {/*<div className="col-md-4 animated fadeInLeft">*/}
                {/*    <p>Naší službou je vývoj a provozování softwaru. Hledání řešení vašich potřeb v informatice.</p>*/}
                {/*</div>*/}
                {/*<div className="col-md-4 animated fadeInLeft text-end">*/}
                {/*    <p>© 2010 - { year }  SEVASOFT všechna práva vyhrazena</p>*/}
                {/*</div>*/}

                {/*<span className="text-muted">Place sticky footer content here.</span>*/}
            </div>
        </footer>
);
}



export default AppFooter;

//
// <footer className="footer-bs">
//     <div className="row">
//         <div className="col-md-3 footer-brand animated fadeInLeft">
//             <a className="brand" href="#home">
//                 <img src="/assets/img/logo-60x34.gif" alt="SEVASOFT Logo" title="Software Atelier" width="60" height="34"/>
//                 &nbsp;&nbsp; SEVASOFT Atelier</a>
//         </div>
//         <div className="col-md-3 footer-brand animated fadeInLeft">
//             <p>Naší službou je vývoj softwaru. Hledání řešení vašich potřeb v informatice.</p>
//         </div>
//         <div className="col-md-3 footer-brand animated fadeInLeft">
//             <p>© 2010 - 2020 SEVASOFT, All rights reserved</p>
//         </div>
//     </div>
// </footer>