// import logo from './logo.svg';

import './App.css';
import * as React from 'react';
// import 'bootstrap/dist/css/bootstrap.css';

function App() {
    return (
        // <div className="row">
        <div className="App" id="home">
            <section id="carouselwelcome" className="App-section-carousel">
            <div  id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                {/*<div className="carousel-inner">*/}
                {/*    <div className="carousel-item active">*/}
                {/*        Solarium*/}
                {/*        /!*<img src="img/slunecnice.jpeg" className="d-block w-100" alt="jmeno"></img>*!/*/}
                {/*    </div>*/}
                {/*    <div className="carousel-item">*/}
                {/*        Masáž pro uvolnění*/}
                {/*        /!*<img src="img/slunecnice.jpeg" className="d-block w-100" alt="jmeno"></img>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"*/}
                {/*        data-bs-slide="prev">*/}
                {/*    <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
                {/*    <span className="visually-hidden">Previous</span>*/}
                {/*</button>*/}
                {/*<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"*/}
                {/*        data-bs-slide="next">*/}
                {/*    <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
                {/*    <span className="visually-hidden">Next</span>*/}
                {/*</button>*/}
                </div>
            </section>

            <header className="App-header">
                <h1>Relax&Beauty Barrandov je místem krásy a relaxace.</h1>
            </header>
            <section className="App-section-slogan">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 ">
                            <p>Kdo jsme</p>

                            <div className="slogan-blok">
                                <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;Relax&Beauty Barrandov je místem krásy a relaxace. Už jen proto, že právě v našem salonu se shromažďuje široká škála služeb.
                                </p>
                                    <p>
                                Díky vysoké profesionalitě a neustálému zdokonalování znalostí a dovedností jsme si jisti, že naši mistři promění všechny vaše nápady ve skutečnost.
                                    </p>
                                        <p>

                                Naším posláním je vytvářet krásu každý den v těch nejpohodlnějších podmínkách. Identifikovat, zlepšovat a udržovat image každého klienta.
                            </p>
                            </div>
                        </div>



                        <div className="col-12 col-md-6 col-lg-6 ">
                            <div className="slogan-blok">
                                Masáže
                                od relaxační do sportovní. Masáž pomáhá odstraňovat toxiny z těla, zlepšuje trávení a také podporuje uvolňování solí. Popsané procesy zvyšují rychlost metabolismu a to je předpokladem pro spalování přebytečného tuku.
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 ">
                            <div className="slogan-blok">
                                Manikúra a pedikúra
                                úhledná manikúra a pedikúra je nezbytnou podmínkou moderního zdokonalovaného dívčího obrazu. Tyto postupy vám umožňují cítit se v jakékoli situaci jisté. Kromě toho, design na nehty pomáhá diverzifikovat obraz, činí ho stylovější a relevantnější.
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/*<section className="App-section-slogan" id="anchKontakt">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}

            {/*            <div className="col-6">*/}
            {/*                <div className="slogan-blok">*/}
            {/*                    <ul className="list-unstyled mb-md-4 mb-lg-0">*/}
            {/*                        <li className="lh-lg"><a*/}
            {/*                            className="text-800 text-decoration-none"*/}
            {/*                            href="https://api.whatsapp.com/send?phone=420777888003&text=M%C3%A1m%20z%C3%A1jem%20o%20va%C5%A1e%20slu%C5%BEby,%20spolupr%C3%A1ci"*/}
            {/*                            target="whatsapp_sevasoft" title="Napište na WhatsApp">WhatsApp</a>*/}
            {/*                        </li>*/}
            {/*                        <li className="lh-lg"><a*/}
            {/*                            className="text-800 text-decoration-none"*/}
            {/*                            href="https://www.t.me/kareldusek" target="whatsapp_sevasoft"*/}
            {/*                            title="Napište na Telegram">Telegram</a></li>*/}
            {/*                        <li className="lh-lg"><a*/}
            {/*                            className="text-800 text-decoration-none" target="email_sevasoft"*/}
            {/*                            title="Zavolejte"*/}
            {/*                            href="tel:+420777888003">Tel&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+420 777 888 003</a></li>*/}
            {/*                        <li className="lh-lg"><a*/}
            {/*                            className="text-800 text-decoration-none"*/}
            {/*                            href="mailto:&#97;&#116;&#101;&#108;&#105;&#101;&#114;&#64;&#115;&#101;&#118;&#97;&#115;&#111;&#102;&#116;&#46;&#99;&#122;">Email*/}
            {/*                            &#97;&#116;&#101;&#108;&#105;&#101;&#114;&#64;&#115;&#101;&#118;&#97;&#115;&#111;&#102;&#116;&#46;&#99;&#122;</a>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="col-6">*/}
            {/*                <div className="slogan-blok">*/}
            {/*                    <ul className="list-unstyled mb-md-4 mb-lg-0 ">*/}
            {/*                        <li className="lh-lg fw-bold">SEVASOFT, s.r.o.*/}
            {/*                        </li>*/}
            {/*                        <li className="lh-lg">Zakšínská 570/2,*/}
            {/*                            190 00 Praha 9*/}
            {/*                        </li>*/}
            {/*                        <li className="lh-lg">IČO: 28440960  DIČ: CZ28440960</li>*/}
            {/*                        <li className="lh-lg">Zapsán u Městského soudu v Praze*/}
            {/*                            <br/>dne: 5.8.2008, Zn.: C/141702</li>*/}

            {/*                        <li className="lh-lg fw-bold">*/}
            {/*                            Bankovní spojení</li>*/}
            {/*                        <li className="lh-lg">*/}
            {/*                            Číslo účtu: 2800027702/2010</li>*/}
            {/*                        <li className="lh-lg">*/}
            {/*                        Majitel: SEVASOFT, s.r.o.*/}
            {/*                        </li>*/}
            {/*                        <li className="lh-lg">*/}
            {/*                        IBAN: CZ5920100000002800027702*/}
            {/*                        </li>*/}
            {/*                        <li className="lh-lg">*/}
            {/*                        BIC: FIOBCZPPXXX*/}
            {/*                        </li>*/}
            {/*                        <li className="lh-lg">*/}
            {/*                        Fio banka, a.s., V Celnici 1028/10, Praha 1*/}
            {/*                        </li>*/}


            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </div>*/}


            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </div>


        // </div>
    );
}

export default App;
