
import './Navbar.css'
import React from 'react'

// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     useParams,
// } from "react-router-dom";
// import {Navbar, Nav, NavDropdown, Form, FormControl, Button, Container} from 'react-bootstrap'
// import Home from './Home';
// import AboutUs from './AboutUs';
// import ContactUs from './ContactUs';
class BootstrapNavbar extends React.Component{
    render(){
        return(
            // navbar-fixed-top
            // sticky-top
            // navbar-light
            <header if="header">
            <nav className="navbar navbar-light navbar-sevasoft">
                <div className="container">
                        <div className="d-sm-inline  align-baseline">
                        <a className="navbar-brand" href="#home">
                        <img src="/assets/img/LogoKompletOrez.webp" alt="SEVASOFT Logo" title="Software Atelier" height="84"/>&nbsp;
                        </a>
                        </div>
                        {/*<div className="align-baseline d-inline  ">*/}
                        {/*<a className="navbar-brand " href="#home">*/}
                        {/*<img  src="/assets/img/logo2-transparent.png" alt="SEVASOFT Logo" title="Software Atelier" height="84"/>*/}
                        {/*&nbsp;*/}
                        {/*</a>*/}
                        {/*</div>*/}
                    <button
                        className="navbar-toggler d-sm-inline-flex"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarMain"
                        aria-controls="basic-navbar-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon navbar-sevasoft-toggler-icon"></span>
                    </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarMain">

            <ul className="navbar-nav me-0 mb-2 mb-lg-0">
                <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="./#">Solná jeskyně</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#anchKontat" tite="laserová epilace">Epilace</a>
                </li>
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="laserová epilace">Solarium</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="laserová epilace">Lymfodrenáž</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="Manikúra">Manikúra</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="Pedikúra">Pedikúra</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="Masáže">Masáže</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="Kosmetika">Kosmetika</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="Kadeřnictví">Kadeřnictví</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="KVoskova a cukrova depilace">Depilace</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#anchKontat" tite="Prodloužení a laminace řas, korekce, barveni a laminace obočí.">Řasy a obočí</a>*/}
                {/*</li>*/}
            </ul>
        </div>
            </div>
         </nav>
            </header>

        )
    }
}

export default BootstrapNavbar;

